import React from 'react';
import * as S from './styles';
import {
  Button,
  Table,
  TableColumns,
  TablePreferences,
  useToast
} from '@veneer/core';
import { json2csv } from 'json-2-csv';

const COLUMNS: TableColumns[] = [
  {
    id: 'metric',
    label: 'Metric'
  },
  {
    id: 'value',
    label: 'Value'
  },
  {
    id: 'assetReference',
    label: 'MFE'
  },
  {
    id: 'tree',
    label: 'Tree'
  }
];

const PREFERENCES: TablePreferences = {
  width: [
    {
      columnId: 'metric',
      width: 50
    },
    {
      columnId: 'value',
      width: 100
    },
    {
      columnId: 'assetReference',
      width: 200
    },
    {
      columnId: 'tree',
      width: 500
    }
  ]
};

const AssetReferenceItem: React.FC<{ asset: string }> = ({ asset }) => {
  const [project, mfeName] = asset.split('/');
  return (
    <S.AssetReference>
      <span className="project">{project}</span>
      <span className="mfe">{mfeName}</span>
    </S.AssetReference>
  );
};

const TreeItem: React.FC<{ tree: string[] }> = ({ tree }) => (
  <S.TreeList>
    {tree.map((asset, i) => (
      <AssetReferenceItem
        asset={asset}
        key={i}
      />
    ))}
  </S.TreeList>
);

const parseForDisplay = (
  entry: ReportEntry
): Record<string, React.ReactElement> => ({
  metric: <span>{entry.metric}</span>,
  value: <span>{entry.value.toFixed(4)}</span>,
  assetReference: <AssetReferenceItem asset={entry.assetReference} />,
  tree: <TreeItem tree={entry.tree} />
});

const parseForExport = (
  entry: ReportEntry
): Record<string, number | string | unknown> => ({
  metric: entry.metric,
  value: entry.value,
  detail: entry.detail,
  assetReference: entry.assetReference,
  tree: entry.tree.join(' > ')
});

const ProfilingMetricsComponent: React.FC = () => {
  const { addToast } = useToast();
  const entries = window.profilingRecords || [];

  const writeDataToClipboard = async (data: string) => {
    try {
      await navigator.clipboard.writeText(data);
      addToast({
        id: Date.now(),
        text: 'Copied to clipboard',
        subtitle: 'Data is ready to be pasted.',
        type: 'positive',
        timeout: 10
      });
    } catch (err) {
      addToast({
        id: Date.now(),
        text: 'Error',
        subtitle: 'Could not copy data to clipboard.',
        type: 'negative',
        timeout: 10
      });
    }
  };

  const onClickExportCSV = async () => {
    const data = await json2csv(entries.map(parseForExport));
    await writeDataToClipboard(data);
  };

  const onClickExportJSON = async () => {
    const data = entries.map(parseForExport);
    await writeDataToClipboard(JSON.stringify(data, null, 2));
  };

  return (
    <>
      <S.Header>
        <h5>Data preview</h5>
        {entries?.length > 0 && (
          <S.ButtonGroup>
            <Button
              small={true}
              onClick={onClickExportCSV}
              appearance="ghost"
            >
              Copy as CSV
            </Button>
            <Button
              small={true}
              onClick={onClickExportJSON}
              appearance="ghost"
            >
              Copy as JSON
            </Button>
          </S.ButtonGroup>
        )}
      </S.Header>
      <Table
        height="500px"
        loading={typeof entries === 'undefined'}
        columns={COLUMNS}
        preferences={PREFERENCES}
        data={entries?.map((entry) => parseForDisplay(entry))}
        loadingDataLength={5}
      />
    </>
  );
};
export default ProfilingMetricsComponent;
