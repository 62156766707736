import axios from 'axios';
import { DeviceDescriptor } from './interface';
import { encriptForMD5 } from 'src/utils/encriptForMD5';

const getResponse = async (skuOrModelName: string) => {
  const response = await axios({
    url: `https://static.hpsmart.com/clientos/assets/latest/devices/${skuOrModelName}/descriptor.json`,
    method: 'GET',
    proxy: {
      protocol: 'http',
      host: 'manual-proxy.us.hpicorp.net',
      port: 8080
    },
    validateStatus(status) {
      return status >= 200 && status < 600;
    }
  });
  return response;
};

export const verifyDeviceName = async (sku?: string, modelName?: string) => {
  const skuOrModelName = sku == '' ? encriptForMD5(modelName) : sku;
  const response = await getResponse(skuOrModelName);
  if (response.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const getDeviceStyle = async (
  sku?: string,
  modelName?: string,
  facing?: string,
  size?: string
) => {
  const skuOrModelName = sku == '' ? encriptForMD5(modelName) : sku;

  const response = await getResponse(skuOrModelName);
  const data: DeviceDescriptor = response.data;

  const verify = () => {
    let getFacing: string = 'LeftFacing';
    const width = data.deviceImage[facing][size].width;
    if (width === '0') {
      const leftWidth = data.deviceImage.LeftFacing[size].width;
      if (leftWidth === '0') {
        const centerWidth = data.deviceImage.CenterFacing[size].width;
        if (centerWidth !== '0') {
          getFacing = 'CenterFacing';
        }
      } else {
        getFacing = 'LeftFacing';
      }
    } else {
      getFacing = facing;
    }
    return getFacing;
  };

  const deviceStyle = {
    width: `${data.deviceImage[verify()][size].width}px`,
    height: `${data.deviceImage[verify()][size].height}px`,
    top: `${data.deviceImage[verify()][size].top}px`,
    right: `${data.deviceImage[verify()][size].right}px`,
    'flex-wrap': 'wrap'
  };
  return deviceStyle;
};
