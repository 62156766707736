import React, { useState } from 'react';
import { DevicePositionNB } from './types';
import { Select } from '@veneer/core';

const SelectItems: React.FC<DevicePositionNB> = ({
  id,
  label,
  items,
  setMessage
}) => {
  const [valueNB, setValueNB] = useState([]);

  const labelValue = label ? label : 'Select';

  const onChange = (selectedOption) => {
    setValueNB([selectedOption.value]);
    setMessage([selectedOption.value]);
  };
  const onClear = () => {
    setValueNB([]);
    setMessage([]);
  };

  return (
    <Select
      options={items}
      id={id}
      data-testid={id}
      label={labelValue}
      placement="bottom"
      helperTextVisibility="auto"
      onChange={onChange}
      onClear={onClear}
      value={valueNB}
    />
  );
};
export default SelectItems;
