import { NorthboundImageType } from 'src/components/ImageNBComponent/GetImageDescriptor/types';

export function sessionContext() {
  let state = undefined;
  let result = undefined;
  state = window?.Shell?.v1?.store?.state;
  result = state ? state?.onboarding?.sessionContext : {};
  return result;
}

export async function closeServiceInstance() {
  window?.Shell?.v1?.serviceRouting?.closeServiceInstance({
    resultData: { result: 'success' }
  });
}

export async function onboardingLoggedIn() {
  const result = !!(await window?.Shell?.v1?.store?.state?.session?.isLoggedIn);
  return result;
}

export async function sessionInterface() {
  const result = (await window?.Shell?.v1?.sessionInterface) || {};
  return result;
}

export async function redirect(url: string) {
  const result = await window?.Shell?.v1?.navigation?.redirect(url);
  return result;
}

export async function getImageNorthbound() {
  let result: NorthboundImageType;
  try {
    result = (await System.import(
      '@clientos/images-northbound-api'
    )) as NorthboundImageType;
  } catch (error) {
    return undefined;
  }

  return result;
}
