import React, { useState } from 'react';
import * as C from '../styles';
import * as S from './styles';
import { TextArea, Button } from '@veneer/core';
import ButtonCopyDataComponent from 'src/components/DefaultComponents/ButtonCopyDataComponent';

const GetToken: React.FC = () => {
  const { getAccessToken } = window.Shell.v1?.authProvider || {};
  const [accessToken, setAccessToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isLoggedIn = window.Shell.v1?.sessionInterface?.isLoggedIn?.();

  return (
    <C.Container>
      <h6>getAccessToken()</h6>
      <C.FormContainer>
        <Button
          small
          loading={isLoading}
          disabled={!isLoggedIn}
          onClick={async () => {
            try {
              setIsLoading(true);
              const response = await getAccessToken(true);
              setAccessToken(response);
            } catch (error) {
              alert(error);
            } finally {
              setIsLoading(false);
            }
          }}
        >
          Get Access Token
        </Button>
        {accessToken ? (
          <ButtonCopyDataComponent
            copyData={accessToken}
            Name="Copy"
            ChangedName="Copied"
          />
        ) : (
          <> </>
        )}
      </C.FormContainer>

      {accessToken ? (
        <S.resultContainer>
          <TextArea
            value={accessToken}
            data-testid="tokenTest"
          />
        </S.resultContainer>
      ) : (
        <></>
      )}
    </C.Container>
  );
};

export default GetToken;
