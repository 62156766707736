import React from 'react';
import * as S from './styles';
import ManifestOverrideComponent from '../ManifestOverrideComponent';
import ImportmapOverrideComponent from '../ImportmapOverrideComponent';
import { MANIFEST_STORAGE_KEY, IMPORTMAP_STORAGE_KEY } from '../../constants';
//import { SelectItem } from './types';
import { Card, Tabs } from '@veneer/core';

const OverrideComponent = () => {
  const [manifest, setManifest] = React.useState<string>(
    localStorage.getItem(MANIFEST_STORAGE_KEY)
  );
  const [importmap, setImportmap] = React.useState<string>(
    localStorage.getItem(IMPORTMAP_STORAGE_KEY)
  );
  const [unsavedImportmap, setUnsavedImportmap] = React.useState<string>('');
  const [unsavedManifest, setUnsavedManifest] = React.useState<string>('');

  const tabs = [
    {
      id: 1,
      label: 'Importmap',
      content: (
        <Card
          background="filled"
          border="dropShadow"
          content={
            <S.cardStyle>
              <ImportmapOverrideComponent
                importmap={importmap}
                setImportmap={setImportmap}
                unsavedImportmap={unsavedImportmap}
                setUnsavedImportmap={setUnsavedImportmap}
              />
            </S.cardStyle>
          }
        />
      )
    },
    {
      id: 2,
      label: 'Manifest',
      content: (
        <Card
          background="filled"
          border="dropShadow"
          content={
            <S.cardStyle>
              <ManifestOverrideComponent
                manifest={manifest}
                setManifest={setManifest}
                unsavedManifest={unsavedManifest}
                setUnsavedManifest={setUnsavedManifest}
              />
            </S.cardStyle>
          }
        />
      )
    }
  ];

  const [selectedTabIdDefault, setSelectedTabIdDefault] = React.useState(
    tabs[0].id
  );

  return (
    <S.tab>
      <Tabs
        controlId="default"
        mode="contained"
        onChangeTab={(id) => setSelectedTabIdDefault(id)}
        selectedTabId={selectedTabIdDefault}
        tabs={tabs}
        type="attached"
      />
    </S.tab>
  );
};

export default OverrideComponent;
