import React, { SetStateAction } from 'react';
import * as S from './styles';
import { Button, IconXCircle } from '@veneer/core';

type MessagePatternProps = {
  message: string;
  messageType: 'normal' | 'Warn' | 'Caution' | string;
  setMessage?: React.Dispatch<SetStateAction<string>>;
  disableSetMessage?: boolean;
};

const MessagePattern: React.FC<MessagePatternProps> = ({
  message,
  messageType,
  setMessage,
  disableSetMessage
}) => {
  const disableSetMessageTemp = disableSetMessage || false;

  const clearData = () => {
    if (setMessage) {
      setMessage('');
    }
  };

  return (
    <div>
      {message ? (
        <S.container hasColor={messageType}>
          <S.message>{message}</S.message>
          {!disableSetMessageTemp ? (
            <S.button>
              <Button
                small={true}
                data-testid="clear-data"
                appearance="ghost"
                colorScheme="warning"
                leadingIcon={<IconXCircle />}
                onClick={clearData}
              />
            </S.button>
          ) : (
            <></>
          )}
        </S.container>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MessagePattern;
