import styled from 'styled-components';

export const container = styled.div<{
  hasColor?: 'normal' | 'Warn' | 'Caution' | string;
}>`
  display: flex;
  border-radius: 50px;
  justify-content: space-between;
  margin-top: 10px;
  ${(props) =>
    (props.hasColor == 'normal' && 'background-color:  #d8f7ba;') ||
    (props.hasColor == 'Warn' && 'background-color:  #f7f4ba;') ||
    (props.hasColor == 'Caution' && 'background-color:  #f7baba;')}
`;

export const message = styled.div`
  display: flex;
  gap: 10px;
  font-size: 14px;
  padding: 10px;
  padding-left: 30px;
`;

export const button = styled.div`
  display: flex;
  padding-right: 30px;
`;
