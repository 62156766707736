import { Tabs } from '@veneer/core';
import React from 'react';
import MessagePattern from 'src/components/ImageNBComponent/MessagePattern';
import SessionContext from './SessionContext';
import ChangeSessions from './ChangeSessions';

const OnboardingComponent: React.FC = () => {
  const odUrl: string =
    localStorage.getItem('onboarding-director-urls') || '{}';
  const odUrlJson = JSON.parse(odUrl);

  const baseSessionUrl = odUrlJson?.sessionResourceUrl;
  const baseContextUrl = odUrlJson?.applicationContextUrl;

  const sessionUrlDefault = baseSessionUrl?.default;
  const contextUrlDefault = baseContextUrl?.default;
  const sessionUrlLocal =
    baseSessionUrl?.local == undefined
      ? sessionUrlDefault
      : baseSessionUrl?.local;
  const contextUrlLocal =
    baseContextUrl?.local == undefined
      ? contextUrlDefault
      : baseContextUrl?.local;

  const sessionUrl = JSON.stringify(sessionUrlLocal);
  const contextUrl = JSON.stringify(contextUrlLocal);

  const handleOnboarding = `Session Url: ${String(contextUrl)
    .replace('"', '')
    .replace('"', '')}${String(sessionUrl).replace('"', '').replace('"', '')}`;

  const tabs = [
    {
      id: 1,
      label: 'Session Context',
      content: <SessionContext />
    },
    {
      id: 2,
      label: 'Change Sessions',
      content: <ChangeSessions />
    }
  ];

  const [selectedTabIdDefault, setSelectedTabIdDefault] = React.useState(
    tabs[0].id
  );

  return (
    <div>
      <MessagePattern
        message={handleOnboarding}
        messageType={'normal'}
        disableSetMessage={true}
      />
      <Tabs
        controlId="default"
        mode="extended"
        onChangeTab={(id) => setSelectedTabIdDefault(id)}
        selectedTabId={selectedTabIdDefault}
        tabs={tabs}
        type="online"
      />
    </div>
  );
};
export default OnboardingComponent;
