import React, { useState } from 'react';
import * as S from './styles';
import { Button, IconUpdate, TextBox } from '@veneer/core';
import { LocalStorageEndpointUrlsType, LOCALSTORAGE_OD_URLS } from './types';

const ChangeSessions: React.FC = () => {
  const [contextUrl] = useState<LocalStorageEndpointUrlsType>(
    () => JSON.parse(window.localStorage.getItem(LOCALSTORAGE_OD_URLS)) || {}
  );

  const [sessionUrl] = useState<LocalStorageEndpointUrlsType>(
    () => JSON.parse(window.localStorage.getItem(LOCALSTORAGE_OD_URLS)) || {}
  );

  const [applicationContextUrl, setApplicationContextUrl] = useState<string>(
    contextUrl?.applicationContextUrl?.local ||
      contextUrl?.applicationContextUrl?.default ||
      ''
  );

  const [sessionResourceUrl, setSessionResourceUrl] = useState<string>(
    sessionUrl?.sessionResourceUrl?.local ||
      sessionUrl?.sessionResourceUrl?.default ||
      ''
  );

  const setLocalStorageItemOnContext = (value: string) => {
    window.localStorage.setItem(
      LOCALSTORAGE_OD_URLS,
      JSON.stringify({
        ...contextUrl,
        applicationContextUrl: {
          ...contextUrl.applicationContextUrl,
          local: value
        }
      })
    );
    window.location.reload();
  };

  const setLocalStorageItemOnSession = (value: string) => {
    window.localStorage.setItem(
      LOCALSTORAGE_OD_URLS,
      JSON.stringify({
        ...sessionUrl,
        sessionResourceUrl: {
          ...sessionUrl.sessionResourceUrl,
          local: value
        }
      })
    );
    window.location.reload();
  };

  return (
    <div>
      <S.Container>
        <S.TextBoxContainer>
          <TextBox
            label="Change Context Url"
            data-testid="change-context-url-testbox"
            value={applicationContextUrl}
            onChange={(value) => setApplicationContextUrl(value)}
          />
        </S.TextBoxContainer>
        <S.buttonContainer>
          <Button
            small={true}
            data-testid="change-context-url"
            leadingIcon={<IconUpdate />}
            onClick={() => setLocalStorageItemOnContext(applicationContextUrl)}
          >
            Change
          </Button>
        </S.buttonContainer>
      </S.Container>
      <S.Container>
        <S.TextBoxContainer>
          <TextBox
            label="Change Session Url"
            data-testid="change-session-url-testbox"
            value={sessionResourceUrl}
            onChange={(value) => setSessionResourceUrl(value)}
          />
        </S.TextBoxContainer>
        <S.buttonContainer>
          <Button
            small={true}
            data-testid="change-session-url"
            leadingIcon={<IconUpdate />}
            onClick={() => setLocalStorageItemOnSession(sessionResourceUrl)}
          >
            Change
          </Button>
        </S.buttonContainer>
      </S.Container>
    </div>
  );
};

export default ChangeSessions;
