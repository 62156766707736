import styled from 'styled-components';

export const nextStage = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-left: 10px;
  gap: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  gap: 10px;
  justify-content: left;
  align-items: center;
`;

export const TextBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 10px;
  gap: 10px;
`;

export const buttonContainer = styled.div`
  height: 40px;
`;

export const redirectContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  gap: 10px;
`;

export const formatButton = styled.div`
  align-content: center;
  display: flex;
  margin: 5px;
  font-size: 20px;
  font-variant: normal;
  text-decoration: none;
  background-color: #0073a8;
  width: auto;
  height: auto;
  padding: 10px;
  border-radius: 50px;
  gap: 10px;
  color: white;
`;

export const textFormatButton = styled.div`
  display: flex;
  font-size: 18px;
  color: white;
`;
