import * as S from './styles';
import React, { useEffect, useState } from 'react';
import { Tabs, ToastProvider } from '@veneer/core';
import OverrideComponent from '../OverrideComponent';
import ActionsComponent from '../ActionsComponent';
import ProfilingMetricsComponent from '../ProfilingMetricsComponent';
import {
  getImageNorthbound,
  sessionContext
} from 'src/utils/getWindowInterface';
import { NorthboundImageType } from '../ImageNBComponent/GetImageDescriptor/types';
import GetImageDescriptor from '../ImageNBComponent/GetImageDescriptor';
import OnboardingComponent from '../OnboardingComponent';

const ModalComponent = ({ openModal, setOpenModal }) => {
  const [imageNB, setImageNB] = useState<NorthboundImageType>();

  const tabs = [
    {
      id: 1,
      label: 'Overrides',
      content: <OverrideComponent />
    },
    {
      id: 2,
      label: 'Actions',
      content: <ActionsComponent />
    }
  ];

  const getImageNBInImportmap = async () => {
    const importMap = await getImageNorthbound();
    setImageNB(importMap);
  };

  if (imageNB) {
    tabs.push({
      id: 3,
      label: 'Image Northbound',
      content: <GetImageDescriptor {...imageNB} />
    });
  }

  if (window.profilingRecords?.length > 0) {
    tabs.push({
      id: 4,
      label: 'Profiling',
      content: <ProfilingMetricsComponent />
    });
  }

  const getContextSession = () => {
    const context = sessionContext();
    return context;
  };

  if (getContextSession()) {
    tabs.push({
      id: 5,
      label: 'Onboarding',
      content: <OnboardingComponent />
    });
  }

  const [selectedTabIdDefault, setSelectedTabIdDefault] = React.useState(
    tabs[0].id
  );

  useEffect(() => {
    getImageNBInImportmap();
  }, []);

  return (
    <ToastProvider position="top">
      <S.ModalTool
        expanded={true}
        align="start"
        show={openModal}
        onClose={() => setOpenModal(false)}
        title="ClientOS Developer Tools"
      >
        <Tabs
          controlId="default"
          mode="extended"
          onChangeTab={(id) => setSelectedTabIdDefault(id)}
          selectedTabId={selectedTabIdDefault}
          tabs={tabs}
          type="online"
        />
      </S.ModalTool>
    </ToastProvider>
  );
};

export default ModalComponent;
