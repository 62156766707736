import { Select } from '@veneer/core';
import styled from 'styled-components';

export const SelectOverride = styled(Select)`
  margin: 15px 0;
`;

export const tab = styled.div`
  display: 'flex';
  margin: 15px 0;
  gap: 15px;
  width: 'auto';
`;

export const cardStyle = styled.div`
  display: 'flex';
  gap: 15px;
  margin: 15px;
`;
