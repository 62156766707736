import { Button, ProgressIndicator } from '@veneer/core';
import React, { useEffect, useState } from 'react';
import JSONEditorComponent from 'src/components/JSONEditorComponent';
import {
  closeServiceInstance,
  sessionContext
} from 'src/utils/getWindowInterface';
import * as S from './styles';

const SessionContext: React.FC = () => {
  const [query, setQuery] = useState('');
  const [messageError, setMessageError] = useState<string>('');
  const [progress, setProgress] = useState<boolean>(false);

  async function getSessions() {
    try {
      const result = await sessionContext();
      setQuery(JSON.stringify(result, null, '\t'));
      setProgress(false);
    } catch (e) {
      setMessageError(e.message);
      setProgress(false);
    }
  }

  async function clickNextStage() {
    setProgress(true);
    await closeServiceInstance();
    setTimeout(async () => {
      await getSessions();
    }, 3000);
  }

  useEffect(() => {
    getSessions();
  }, []);

  return (
    <div>
      <S.nextStage>
        <Button
          data-testid="next-stage-button"
          onClick={() => {
            clickNextStage();
          }}
        >
          Next Stage
        </Button>
      </S.nextStage>
      {progress ? (
        <ProgressIndicator
          appearance="linear"
          behavior="indeterminate"
          size="small"
        />
      ) : (
        <> </>
      )}
      <JSONEditorComponent
        name="Session Context"
        value={query}
        onChange={() => {}}
        annotations={
          messageError
            ? [{ row: 0, column: 0, type: 'error', text: messageError }]
            : []
        }
      />
    </div>
  );
};
export default SessionContext;
