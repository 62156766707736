import {
  Button,
  ButtonColorScheme,
  IconCheckmark,
  IconDocumentCopy
} from '@veneer/core';
import React, { ReactNode, useState } from 'react';
import * as S from './styles';
import { copyNorthbound } from 'src/utils/copyNorthbound';

type ButtonCopyDataType = {
  copyData: string;
  ChangedName: string;
  Name: string;
};

const ButtonCopyDataComponent: React.FC<ButtonCopyDataType> = ({
  copyData,
  ChangedName,
  Name
}) => {
  const [icon, setIcon] = useState<ReactNode>(<IconDocumentCopy />);
  const [appearance, setAppearance] = useState<
    'ghost' | 'primary' | 'secondary'
  >('ghost');
  const [colorScheme, setColorScheme] = useState<ButtonColorScheme>('default');
  const [name, setName] = useState<string>(Name);

  function timeoutChangeIcon() {
    setTimeout(() => {
      setIcon(<IconDocumentCopy />);
      setAppearance('ghost');
      setColorScheme('default');
      setName(Name);
    }, 3000);
  }

  const clickToCopyValue = async () => {
    if (copyData) {
      await copyNorthbound(copyData);
      setIcon(<IconCheckmark />);
      setAppearance('primary');
      setColorScheme('positive');
      setName(ChangedName);
      timeoutChangeIcon();
    }
  };

  return (
    <S.buttonContainer>
      <Button
        small
        data-testid="copy-button"
        appearance={appearance}
        colorScheme={colorScheme}
        leadingIcon={icon}
        onClick={clickToCopyValue}
      >
        {name}
      </Button>
    </S.buttonContainer>
  );
};
export default ButtonCopyDataComponent;
